import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
  calculatePrimes,
  getPayload,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { TextError } from '@components/TextError';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import {
  arraysAreSame,
  fetchIncomes,
  isEmptyValue,
  objectsAreSame,
} from '@models/worksiteCreation/utils/functions';
import {
  IGraph,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import NoAid from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/NoAid';
import { OperationTypeEnum } from '@utils/enums';
import { useTranslation } from 'react-i18next';
import { isInstaller } from '@utils/roles';
import { AuthContext } from '@context/authContext';
import { IDefaultObject } from '../../../../../types/globalTypes';
import { CardForm } from '../../CardForm';
import { RenderFormGraphOperation } from './RenderFormGraphOperation';

function StepDetailOperation() {
  const {
    graphOperation,
    simulatorDataOperations,
    simulatorData,
    updateStepActiveWorksiteCreation,
    updateSimulatorData,
    updateIsLoading,
    conventionActive,
    worksiteOperationType,
    updateDisabledNextButton,
    updateSimulatorDataOperation,
    lastStep,
    noAidOperationToDisplay,
    updateNoAidOperationToDisplay,
    incomesOptionsArray,
    worksiteAddress,
    updateIncomesOptionsArray,
    worksiteDatas,
  } = useContext(WorksiteCreationContext);

  const { user } = useContext(AuthContext);

  const { t } = useTranslation();
  const [errorCalculPrimeMessage, setErrorCalculPrimeMessage] =
    useState<string>('');

  const [isSubmitWithNoAid, setIsSubmitWithNoAid] = useState<boolean>(false);

  const initialData = useMemo(() => {
    return { operations: simulatorDataOperations, general: simulatorData };
  }, []);

  const [graphErrors, setGraphErrors] = useState<string[]>([]);

  const noChange = useMemo(() => {
    if (lastStep <= StepsWorksiteCreationEnum.SIMULATION_OPERATIONDETAILS) {
      return false;
    }

    const initialOps = initialData.operations as IDefaultObject[];
    const currentOps = simulatorDataOperations as IDefaultObject[];

    const { operations: op1, ts: ts1, ...currentObj } = simulatorData;
    const { operations: op2, ts: ts2, ...initialObj } = initialData.general;

    const obj = objectsAreSame(initialObj, currentObj);

    const arr = arraysAreSame(initialOps, currentOps, 'operation.id');

    return obj && arr;
  }, [simulatorDataOperations, simulatorData, lastStep]);

  const onSubmitFormGraphGeneral = async () => {
    if (noAidOperationToDisplay) {
      setIsSubmitWithNoAid(true);
      return;
    }
    let canGoToNextStep = true;
    const withAmounts =
      !conventionActive.can_use_custom_prices || !!isInstaller(user);

    if (!noChange) {
      updateIsLoading(true);
      let response: boolean;
      if (withAmounts) {
        response = await calculatePrimes(
          simulatorData,
          simulatorDataOperations,
          updateIsLoading,
          updateSimulatorData,
          updateSimulatorDataOperation,
          setErrorCalculPrimeMessage,
          worksiteOperationType,
          conventionActive.id,
          incomesOptionsArray,
          t,
          worksiteDatas.incentive_type,
          updateNoAidOperationToDisplay
        );
      } else {
        const responseData = await getPayload(
          worksiteDatas,
          simulatorData,
          simulatorDataOperations,
          setErrorCalculPrimeMessage,
          worksiteOperationType,
          conventionActive.id,
          incomesOptionsArray,
          t
        );
        updateSimulatorDataOperation(responseData);
        response = !!responseData;
      }
      updateIsLoading(false);

      if (!response) canGoToNextStep = false;
    }

    if (canGoToNextStep) {
      const nextStep = !withAmounts
        ? StepsWorksiteCreationEnum.SIMULATION_PRIX
        : StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF;
      updateStepActiveWorksiteCreation(nextStep); //
    }
  };

  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const checkOperationVariationScopUnit = () => {
    const simulatorDataOperationsArray = Object.values(simulatorDataOperations);

    for (let i = 0; i < simulatorDataOperationsArray.length; i += 1) {
      const operationVariation =
        simulatorDataOperationsArray[i]['operation.variation'];
      if (Array.isArray(operationVariation)) {
        if (
          operationVariation.reduce(
            (acc, unity) => acc + Number(unity.surface),
            0
          ) > simulatorData['general.surface']
        ) {
          return true;
        }
        for (let j = 0; j < operationVariation.length; j += 1) {
          const keys = Object.keys(operationVariation[j]);
          for (let k = 0; k < keys.length; k += 1) {
            if (operationVariation[j][keys[k]] === '') {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const findOperationItem = (operationsData: any[], key: string) => {
    return operationsData.find((item) => key in item);
  };

  const totalBoilersPower = (operationsData: ISimulatorDataOperation[]) => {
    const boilersItem =
      findOperationItem(operationsData, 'operation.boilerDetails') ||
      findOperationItem(operationsData, 'operation.deliveryPostDetails');

    if (
      boilersItem &&
      'operation.boilerDetails' in boilersItem &&
      typeof boilersItem['operation.boilerDetails'] === 'object' &&
      boilersItem['operation.boilerDetails'] !== null
    ) {
      const boilerDetails = boilersItem['operation.boilerDetails'] as {
        boiler: { power: string }[];
      };

      if (Array.isArray(boilerDetails.boiler)) {
        return boilerDetails.boiler.reduce((total, boiler) => {
          if ('power' in boiler) {
            return total + Number(boiler.power);
          }
          return total;
        }, 0);
      }
    }

    return 0;
  };

  const totalHeatingPower = (operationsData: ISimulatorDataOperation[]) => {
    const heatingPowerKey = 'operation.totalHeatingPower';
    const connectedPowerKey = 'operation.totalConnectedPower';
    let result: any = null;

    operationsData.forEach((obj) => {
      if (heatingPowerKey in obj) {
        const value = obj[heatingPowerKey];
        result = typeof value === 'string' ? Number(value) : value;
      } else if (connectedPowerKey in obj) {
        const value = obj[connectedPowerKey];
        result = typeof value === 'string' ? Number(value) : value;
      }
    });

    return result ?? 0;
  };

  const boilersPowerIsValid = () => {
    const totalPower = totalHeatingPower(simulatorDataOperations);
    const boilersPower = totalBoilersPower(simulatorDataOperations);

    return totalPower > 0 ? totalPower >= boilersPower : true;
  };

  const boilersPowerFormNotValid = (
    operationsData: ISimulatorDataOperation[]
  ) => {
    const boilersItem = findOperationItem(
      operationsData,
      'operation.boilerDetails'
    );

    if (
      !boilersItem ||
      typeof boilersItem['operation.boilerDetails'] !== 'object' ||
      boilersItem['operation.boilerDetails'] === null
    ) {
      return false;
    }

    const boilerDetails = boilersItem['operation.boilerDetails'] as {
      boiler: { power: string }[];
    };
    if (
      !Array.isArray(boilerDetails.boiler) ||
      boilerDetails.boiler.length === 0
    ) {
      return true;
    }

    const invalidBoilerPower = boilerDetails.boiler.some(
      (boiler) => !boiler.power || boiler.power === ''
    );

    if (invalidBoilerPower) {
      return true;
    }

    return !boilersPowerIsValid();
  };

  // Cette fonction vérifie si la condition d'un élément est satisfaite en fonction de simulatorData
  const isConditionSatisfied = (condition: any) => {
    if (!condition || Object.keys(condition).length === 0) return false;
    const { step, answer } = condition.generalSimulator;
    const simulatorStepValue = simulatorData[`general.${step}`];
    if (step === 'zipcode') {
      const zipcode = String(simulatorData['general.zipcode']);

      const isNotEqual = answer.startsWith('!=');

      const zipcodeNumber = answer.match(/\d+/)[0]; // '!=97' => '97'

      return isNotEqual
        ? !zipcode.startsWith(zipcodeNumber)
        : zipcode.startsWith(zipcodeNumber);
    }
    return simulatorStepValue === answer;
  };

  // Cette fonction filtre le tree de chaque opération basé sur les conditions et simulatorData
  const getFilteredOperationTree = (operation: IGraph) => {
    // Filtrer les éléments de tree qui satisfont leurs conditions
    const filteredTree = operation.tree?.filter((treeElement) => {
      return isConditionSatisfied(treeElement.condition);
    });

    // Si aucun élément ne satisfait sa condition, retourner ceux sans condition
    if (filteredTree?.length === 0) {
      return operation.tree?.filter((treeElement) => {
        return Object.keys(treeElement.condition || {}).length === 0;
      });
    }
    return filteredTree;
  };

  // Utiliser getFilteredOperationTree pour obtenir les trees filtrés tout en conservant les autres éléments de l'opération
  const operationsWithFilteredTree = graphOperation.map((operation) => {
    const filteredTree = getFilteredOperationTree(operation);
    return { ...operation, tree: filteredTree };
  });

  const checkIfOperationHasQuestion = (operation: IGraph) =>
    !!operation.tree && operation.tree.length === 1 && !operation.tree[0].name;

  const noQuestionInAnyOperation = operationsWithFilteredTree.every(
    (operation) => checkIfOperationHasQuestion(operation)
  );

  useEffect(() => {
    let emptyData = false;
    const haveError = errors && Object.keys(errors).length > 0;

    Object.values(simulatorDataOperations).forEach((obj) => {
      Object.entries(obj).forEach(([key, val]) => {
        if (key.includes('operation.') && isEmptyValue(val)) {
          emptyData = true;
        }
      });

      if ('operation.variation' in obj) {
        emptyData = checkOperationVariationScopUnit();
      }
    });

    const btnDisabled =
      haveError ||
      boilersPowerFormNotValid(simulatorDataOperations) ||
      emptyData ||
      graphErrors.length > 0;

    updateDisabledNextButton(btnDisabled);
  }, [JSON.stringify(simulatorDataOperations), graphErrors]);

  useEffect(() => {
    if (
      (!incomesOptionsArray || incomesOptionsArray.length === 0) &&
      OperationTypeEnum.B2C === worksiteOperationType
    ) {
      fetchIncomes(
        String(simulatorData['general.persons']),
        worksiteAddress,
        simulatorData,
        updateIncomesOptionsArray,
        t
      );
    }
  }, []);

  return (
    <div
      className={`w-full pb-[20rem] ${
        graphOperation.length > 1 ? 'flex flex-wrap' : ''
      }`}
    >
      {noQuestionInAnyOperation && (
        <form
          id="sendGraphOperation"
          onSubmit={handleSubmit(
            onSubmitFormGraphGeneral as (data: FieldValues) => void
          )}
        >
          <div className="mt-[1.5rem]">
            {operationsWithFilteredTree.length === 1
              ? t(
                  'worksite_creation.simulation.no_information_required_for_one_operation'
                )
              : t(
                  'worksite_creation.simulation.no_information_required_for_operations'
                )}
          </div>
        </form>
      )}
      {operationsWithFilteredTree.map((operation, index) => {
        const noQuestion = checkIfOperationHasQuestion(operation);
        if (!operation || noQuestion) {
          return null;
        }

        return (
          <CardForm
            title={operation.name}
            subtitle={operation.description}
            idForm="sendGraphOperation"
            key={operation.name + operation.value}
            onSubmit={onSubmitFormGraphGeneral}
            addClass="flexFormGraph w-full pb-[2rem]"
            methods={methods}
          >
            <div className="mt-[1.5rem]">
              {noAidOperationToDisplay && isSubmitWithNoAid ? (
                <NoAid description={noAidOperationToDisplay.description} />
              ) : (
                operation.tree?.map((children) => {
                  return (
                    <div>
                      <RenderFormGraphOperation
                        key={children.value + children.name}
                        graph={children}
                        index={index}
                        setErrorCalculPrimeMessage={setErrorCalculPrimeMessage}
                        boilerPowerError={!boilersPowerIsValid()}
                        setGraphErrors={setGraphErrors}
                        operationTree={operation.tree}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </CardForm>
        );
      })}
      <TextError errorMessage={errorCalculPrimeMessage} />
    </div>
  );
}

export { StepDetailOperation };
