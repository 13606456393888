import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { calculateRenovationGlobale } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import {
  arraysAreSame,
  getRenovGlobalData,
  objectsAreSame,
} from '@models/worksiteCreation/utils/functions';
import { t } from 'i18next';
import { RenderFormGraphOperation } from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/RenderFormGraphOperation';
import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import NoAid from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/NoAid';
import { IDefaultObject } from '../../../../types/globalTypes';
import { CardForm } from '../CardForm';

interface StepEnergyDepositAndPrimesProps {
  operations: IGraph[];
}

function StepEnergyDepositAndPrimes({
  operations,
}: StepEnergyDepositAndPrimesProps) {
  const {
    updateSimulatorData,
    scenario,
    listOperationSelected,
    updateStepActiveWorksiteCreation,
    simulatorData,
    simulatorDataOperations,
    updateSimulatorDataOperation,
    updateIsLoading,
    isLoading,
    lastStep,
    worksiteOperationType,
    noAidOperationToDisplay,
    updateDisabledNextButton,
  } = useContext(WorksiteCreationContext);
  const methods = useForm();

  const [graphErrors, setGraphErrors] = useState<string[]>([]);

  const [isSubmitWithNoAid, setIsSubmitWithNoAid] = useState<boolean>(false);

  const initialDataGeneral = useMemo(() => {
    return simulatorData;
  }, []);

  const initialDataOperations = useMemo(() => {
    return simulatorDataOperations;
  }, []);

  const isNoChange = () => {
    if (lastStep !== StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF) {
      return false;
    }

    const initialOps = initialDataOperations as IDefaultObject[];
    const currentOps = simulatorDataOperations as IDefaultObject[];

    const { operations: op1, ts: ts1, ...currentObj } = simulatorData;
    const { operations: op2, ts: ts2, ...initialObj } = initialDataGeneral;

    const obj = objectsAreSame(initialObj, currentObj);

    const arr = arraysAreSame(initialOps, currentOps, 'operation.id');

    return obj && arr;
  };

  const onSubmit = async (data: FieldValues) => {
    if (noAidOperationToDisplay) {
      setIsSubmitWithNoAid(true);
      return;
    }
    updateIsLoading(true);
    const dataToSend = getRenovGlobalData(
      data[0].operation,
      Number(scenario?.value),
      listOperationSelected,
      simulatorData,
      worksiteOperationType
    );

    if (!isNoChange()) {
      await calculateRenovationGlobale(
        dataToSend,
        updateIsLoading,
        updateSimulatorData,
        updateSimulatorDataOperation
      );
    }

    updateStepActiveWorksiteCreation(
      StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF
    );
  };

  //

  if (isLoading && operations[0].tree && operations[0].tree?.length > 1)
    return (
      <div className="mt-[3rem]">
        <LoaderSkeleton height="2rem" addClass="w-1/2" />
        <LoaderSkeleton height="1rem" addClass="w-1/3 mt-4" />
      </div>
    );

  useEffect(() => {
    if (graphErrors.length > 0) updateDisabledNextButton(true);
  }, [graphErrors]);

  return (
    <div className="mt-[1.25rem]">
      <div
        className={`max-h-[85%] pr-[2.5rem] overflow-y-scroll md:absolute w-full ${
          operations.length > 1 ? 'flex flex-wrap' : ''
        }`}
      >
        {operations.map((operation, index) => {
          return (
            <CardForm
              title={t('worksite_creation.global_renovation.subtitle')}
              subtitle={`${t('worksite_creation.global_renovation.info')}`}
              idForm="calculGisementPrimes"
              key={operation.name + operation.value}
              onSubmit={onSubmit}
              addClass="flexFormGraph w-full pb-[2rem]"
              methods={methods}
            >
              <div className="mt-[1.5rem] row grid grid-cols-2 gap-4">
                {noAidOperationToDisplay && isSubmitWithNoAid ? (
                  <NoAid description={noAidOperationToDisplay.description} />
                ) : (
                  operation.tree?.map((children) => {
                    return (
                      <RenderFormGraphOperation
                        key={children.value + children.name}
                        graph={children}
                        index={index}
                        setGraphErrors={setGraphErrors}
                        operationTree={operation.tree}
                      />
                    );
                  })
                )}
              </div>
            </CardForm>
          );
        })}
      </div>
    </div>
  );
}

export { StepEnergyDepositAndPrimes };
