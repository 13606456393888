/* eslint-disable no-param-reassign */

import { isBefore, startOfToday, parse } from 'date-fns';
import { Dispatch, RefObject, SetStateAction } from 'react';
import PDFMerger from 'pdf-merger-js/browser';
import { toast } from 'react-toastify';
import { initDocumentActive } from '@utils/initialState';
import {
  IFiscalDeclaration,
  IWorksite,
  IWorksiteAddress,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { PARTNERS_MENU_MAPPING } from '@models/partners/utils/partnersConstants';
import { taxNoticeReferenceRegex } from '@utils/regex';
import { fileTypeEnum } from '@utils/enums';
import {
  BENEFICIARIES_ROUTES_WITH_ID,
  CONTRACTS_ROUTES_WITH_ID,
  DEPOSITS_ROUTES_WITH_ID,
  PARTNERS_ROUTES_WITH_ID,
  WORKSITES_ROUTES_WITH_ID,
} from '@utils/routesUrls';
import { FieldValues } from 'react-hook-form';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import {
  IActiveDocument,
  IGlobalEnumType,
  ILinkedFile,
} from '../types/globalTypes';
import { numberWithSeparator } from './format';
import { ENTITY_TYPES } from './roles';
import packageJson from '../../package.json';

// Modifier une couleur hexa en rgba avec une opacité
export const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode ? hexCode.replace('#', '') : '#5D6AD2'.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity > 1 && opacity <= 100) {
    opacity /= 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

// Récupère la date au format DD/MM/YYYY
export const getDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

/** Functions upload pour Document */
// Déclenche le onClick de l'input quand click sur ButtonOpx
export const handleUploadClick = (input: RefObject<HTMLInputElement>) => {
  input.current?.click();
};

// Transforme date en format DD/MM/YYYY
export const transformIsoDate = (date: string) => {
  return new Date(date).toLocaleDateString();
};

// Nombres avec 2 chiffres après la virgule
export const roundFormat = (value: number | string) => {
  const valueFormat = typeof value === 'string' ? parseFloat(value) : value;
  return (Math.round(valueFormat * 100) / 100).toFixed(2).toLocaleString();
};

// Séparation des milliers par un espace
export const numFormatSpace = (
  a: string | number,
  withoutDecimal?: boolean
) => {
  let str = roundFormat(a);
  if (withoutDecimal) {
    str = Math.round(parseFloat(str)).toString();
  }
  const decimal = str !== null ? str.split('.', str.length - 1) : [''];
  if (decimal[1] === '00') {
    return decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
  }
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
};

// conversion de k en M: kWhc en MWhc
export const convertKiloToMega = (k: number | null) => {
  const mega = k ? k / 1000 : 0;
  return numberWithSeparator(String(mega));
};

// conversion de k en G: kWhc en GWhc
export const convertKiloToGiga = (k: number | null) => {
  const mega = k ? k / 1000000 : 0;
  return numberWithSeparator(String(mega));
};

// conversion de M en k: MWhc en kWhc
export const convertMegaToKilo = (m: number | null) => {
  const kilo = m ? m * 1000 : 0;
  return numberWithSeparator(String(kilo));
};

// conversion de M en k: MWhc en kWhc
export const convertGigaToKilo = (m: number | null) => {
  const kilo = m ? m * 1000000 : 0;
  return numberWithSeparator(String(kilo));
};

// conversion de Mega à Giga : MWhc en GWhc à partir de 1000 MWhc
// conversion de Giga à Tera : GWhc en TWhc à partir de 1 000 000 MWhc
export const convertVolumeFromMega = (m: number | null) => {
  let kilo = m || 0;
  let unit = 'MWhc';
  if (m && m >= 1000) {
    kilo = m / 1000;
    unit = 'GWhc';
  }
  if (m && m >= 1000000) {
    kilo = m / 1000000;
    unit = 'TWhc';
  }
  return `${kilo.toLocaleString('fr-FR')} ${unit}`;
};

export const toEnNumberAsString = (numberStr: string) => {
  // replace , by . and space by ''
  const enNumberAsString = numberStr.replace(',', '.').replace(/ /g, '');
  return enNumberAsString;
};

export const isBeforeToday = (d: string): boolean => {
  const formats = ['dd/MM/yyyy', 'yyyy-MM-dd'];

  // Fonction pour parser la date avec plusieurs formats
  const parseDate = (dateStr: string): Date | null => {
    const parsedDate = formats
      .map((formatStr) => parse(dateStr, formatStr, new Date()))
      .find((parsed) => !Number.isNaN(parsed.getTime()));

    return parsedDate || null;
  };

  const ddate = parseDate(d || '');

  // Vérifier si la date est valide
  if (!ddate) {
    return false; // Retourne false si la date est invalide
  }

  const today = startOfToday();
  const isToday = ddate.toDateString() === today.toDateString();
  const isBeforeT = isBefore(ddate, today);

  // Retourner true si la date est avant aujourd'hui, sinon false
  return isToday ? false : isBeforeT;
};

export const isBeforeDate = (d: string, dref: string): boolean => {
  const formats = ['dd/MM/yyyy', 'yyyy-MM-dd'];

  // Fonction pour parser la date avec plusieurs formats
  const parseDate = (dateStr: string): Date | null => {
    const parsedDate = formats
      .map((formatStr) => parse(dateStr, formatStr, new Date()))
      .find((parsed) => !Number.isNaN(parsed.getTime()));

    return parsedDate || null;
  };

  const ddate = parseDate(d || '');
  const ddref = parseDate(dref || '');

  // Vérifier si les dates sont valides
  if (!ddate || !ddref) {
    return false;
  }

  // Comparer les dates
  return isBefore(ddate, ddref);
};

export const mergePdfFiles = (
  blobs: Blob[],
  list: string,
  updateDocumentActive: Dispatch<SetStateAction<IActiveDocument>>
) => {
  const render = async () => {
    const merger = new PDFMerger();

    await Promise.all(
      blobs.map(async (blob) => {
        await merger.add(blob);
      })
    );

    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    updateDocumentActive({
      list,
      listAsTitle: true,
      document: {
        ...initDocumentActive.document,
        file_url: url,
      },
    });
  };

  return render().catch((err) => {
    throw toast.error(err);
  });
};

export const getSearchElement = (
  type: number,
  element: { [x: string]: string | number },
  entityType: number | undefined
): { text: string; link: string } => {
  let text = '';
  let link = '';

  const getBeneficiaryLabel = (elt: { [x: string]: string | number }) => {
    if (elt.company_name) return String(elt.company_name);
    return `${elt.firstname} ${elt.lastname}`;
  };

  const getWorksiteLabel = (elt: { [x: string]: string | number }) => {
    if (!element.address && !element.postal_code && !element?.city) {
      return `Chantier - ${getBeneficiaryLabel(elt)}`;
    }

    return `${element.address} ${element.postal_code} ${element.city}`;
  };

  const getContractLink = () => {
    // gerer le cas connecté en tant qu'installateur
    element.contract_type =
      entityType === ENTITY_TYPES.INSTALLATEUR
        ? ContractTypes.CONVENTION
        : element.contract_type;
    switch (element.contract_type) {
      case ContractTypes.CONVENTION:
        return CONTRACTS_ROUTES_WITH_ID(element.id).CONVENTION_INSTALLER_VIEW;
      case ContractTypes.TRIPARTITE:
        return CONTRACTS_ROUTES_WITH_ID(element.id).CONVENTION_BENEFICIARY_VIEW;
      case ContractTypes.MANDAT:
        return CONTRACTS_ROUTES_WITH_ID(element.id)
          .CONTRACT_REPRESENTATIVE_DEPOSITOR_VIEW;
      case ContractTypes.MANDAT_NON_DEPOSANT:
        return CONTRACTS_ROUTES_WITH_ID(element.id)
          .CONTRACT_REPRESENTATIVE_NON_DEPOSITOR_VIEW;
      default:
        return CONTRACTS_ROUTES_WITH_ID(element.id).CONTRACT_SALE_VIEW;
    }
  };

  switch (type) {
    case 1: // Beneficiaires
      text = getBeneficiaryLabel(element);
      link = BENEFICIARIES_ROUTES_WITH_ID(element.id).BENEFICIARY_VIEW;
      break;
    case 2: // chantiers
    case 3: // simulation
      text = getWorksiteLabel(element);
      link =
        element.creation_status === '7'
          ? WORKSITES_ROUTES_WITH_ID(element.id).WORKSITES_VIEW
          : WORKSITES_ROUTES_WITH_ID(element.id).WORKSITES_NEW_ID;
      break;
    case 4: // Sous traitants
      text = `${element.company_name}`;
      link = `${
        PARTNERS_ROUTES_WITH_ID(element.id).PARTNER_SUBCONTRACTOR_VIEW
      }#${PARTNERS_MENU_MAPPING[ENTITY_TYPES.SOUS_TRAITANT].hash}`;
      break;
    case 5: // Installateurs
      text = `${element.company_name}`;
      link = PARTNERS_ROUTES_WITH_ID(element.id).INSTALLER_VIEW;
      break;
    case 6: // Partenaires
      text = `${element.company_name}`;
      link = `${PARTNERS_ROUTES_WITH_ID(element.id).PARTNER_VIEW}#${
        PARTNERS_MENU_MAPPING[element.from_entity_type].hash
      }`;
      break;
    case 7: // COFRAC
      text = `${element.name}`;
      link = PARTNERS_ROUTES_WITH_ID(element.id).COFRAC_VIEW;
      break;
    case 8: // Depots
      text = `${element.reference}`;
      link = DEPOSITS_ROUTES_WITH_ID(element.id).DEPOSIT_VIEW;
      break;
    case 9: // Contrats
      text = `${element.reference}`;
      link = getContractLink();
      break;
    default:
      break;
  }

  return { text, link };
};

export const getFileNameFromUrl = (url: string | null) => {
  //
  if (!url) return '';

  const lastSlash = url.lastIndexOf('/');

  return url.substring(lastSlash + 1);
};
export const extractAllWorksiteLinkedFiles = (
  worksiteDatas: IWorksite | IWorksiteDetails
) => {
  const worksiteOperationDocuments = worksiteDatas.worksites_operations
    .map((operation) => operation.linkedFiles)
    .reduce((acc, val) => acc.concat(val), []);
  if (worksiteDatas.linkedFiles !== undefined) {
    return [...worksiteDatas.linkedFiles, ...worksiteOperationDocuments];
  }
  return worksiteOperationDocuments;
};

export const extractCustomFieldFiles = (worksiteDatas: any) => {
  if (worksiteDatas.custom_fields !== undefined) {
    const tmpCustomFiles: any[] = [];
    worksiteDatas.custom_fields.forEach((field: any) => {
      if (field.type === fileTypeEnum.CUSTOM_FIELD && field.is_active) {
        const tmpField = {
          customfield_id: field.id,
          title: field.public_name,
          file_type: field.type,
          documents:
            field.documents && field.documents.length > 0
              ? field.documents
              : [
                  {
                    file_name: field.public_name,
                    file_url: field.file_url,
                    file_type: field.type,
                    status: 1,
                    is_deletable: true,
                    relation_ids: [worksiteDatas.id],
                    relation_type: 'worksite',
                    linked_entity_id: null,
                    mandatory: field.is_required,
                    commentary: field.comment,
                    created_by: field.created_by,
                    custom_title: field.public_name,
                  },
                ],
          required: field.is_required,
          is_operation_document: false,
        };
        tmpCustomFiles.push(tmpField);
      }
    });

    return tmpCustomFiles;
  }
  return [];
};

interface OriginalObject {
  [key: string]: any;
}

export const transformObjectList = (
  objects: OriginalObject[],
  labelKey: keyof OriginalObject,
  valueKey: keyof OriginalObject
) => {
  return objects.map((obj) => ({
    label: obj[labelKey],
    value: obj[valueKey],
  }));
};

export const addFiscalNameToTitle = (
  document: ILinkedFile,
  globalEnum: IGlobalEnumType,
  worksiteDatas: any
) => {
  const title = globalEnum.linked_file_type[document.file_type];
  if (worksiteDatas && document.relation_type === 'fiscal_declaration') {
    const fiscalDeclarationsTab =
      worksiteDatas.fiscalDeclarations as IFiscalDeclaration[];
    const associatedFiscalDeclaration = fiscalDeclarationsTab.find(
      (f: IFiscalDeclaration) =>
        document.relation_ids !== null && f.id === document.relation_ids[0]
    );
    if (associatedFiscalDeclaration) {
      const formattedFirstName =
        associatedFiscalDeclaration.firstname.charAt(0).toUpperCase() +
        associatedFiscalDeclaration.firstname.slice(1).toLowerCase();

      const formattedLastName =
        associatedFiscalDeclaration.lastname.toUpperCase();

      const finalName = `${formattedFirstName} ${formattedLastName}`;

      return `${title} ${finalName}`;
    }
  }
  return title;
};

// Fonction pour vérifier si les valeurs ne sont pas nulles, vides ou indéfinies
export const isValueValid = (value: any): boolean =>
  value !== null && value !== '' && value !== undefined;

/**
 * Raccourcit une chaîne de caractères sans espace en conservant le début et la fin,
 * séparés par des points de suspension. Si la chaîne contient des espaces (comme une phrase),
 * elle ne sera pas raccourcie car elle peut naturellement être divisée en lignes.
 *
 * @param {string} str - La chaîne de caractères à raccourcir.
 * @param {number} maxLength - La longueur maximale de la chaîne raccourcie.
 * @returns {string} - La chaîne raccourcie avec des points de suspension si nécessaire.
 */
export const shortenStringWithEllipsis = (
  str: string,
  maxLength: number
): string => {
  if (str.length <= maxLength || str.includes(' ')) return str;

  const startLength = Math.floor(maxLength / 2) - 2;
  const endLength = Math.ceil(maxLength / 2) + 3;

  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);

  return `${start}...${end}`;
};

export const validSiren = (value: string) => {
  if (value.length !== 9 || !/^\d{9}$/.test(value)) {
    return false; // Vérifie la longueur et si tous les caractères sont des chiffres
  }

  let sum = 0;
  for (let index = 0; index < 9; index += 1) {
    let number = parseInt(value[index], 10);
    if (index % 2 !== 0) {
      number *= 2;
      if (number > 9) {
        number -= 9;
      }
    }
    sum += number;
  }

  return sum % 10 === 0; // Vérifie si la somme des chiffres est un multiple de 10
};

export const objectToStringArray = (object: {
  [x: string]: string;
}): string[] => {
  return Object.values(object).map((elt) => elt);
};
export const findKeyByValue = (obj: { [x: string]: string }, value: string) => {
  let resultKey: string | null = null;

  Object.keys(obj).forEach((key) => {
    if (obj[key] === value && resultKey === null) {
      resultKey = key;
    }
  });

  return String(resultKey);
};

export const incorrectNoticeReferenceYear = (fiscalReference: string) => {
  const date = new Date();
  const lastCharYear = date.getFullYear().toString().substring(2, 4);

  return (
    fiscalReference.length > 1 &&
    (Number(fiscalReference.substring(0, 2)) > Number(lastCharYear) ||
      Number(fiscalReference.substring(0, 2)) <= Number(lastCharYear) - 3)
  );
};

export const fiscalReferenceIsNotValid = (fiscalReference: string) => {
  return (
    (fiscalReference.length > 12 &&
      !taxNoticeReferenceRegex.test(fiscalReference)) ||
    incorrectNoticeReferenceYear(fiscalReference)
  );
};

export const formatAddressAndLatLngFromForm = async (
  formData: FieldValues,
  addressData: IBeneficiaryAddress | undefined,
  updateWorksiteAddressFromKeyValue: (
    updates: Partial<IWorksiteAddress>
  ) => void
) => {
  let address =
    formData.numberStreet && formData.street
      ? `${formData.address}`
      : `${addressData?.address} `;

  if (formData.address) address = formData.address;

  let postalCode = formData.zip ? formData.zip : `${addressData?.postal_code} `;

  if (formData.zipcode) postalCode = formData.zipcode;

  const city = formData.city ? formData.city : `${addressData?.city} `;

  const country = formData.country
    ? formData.country
    : `${addressData?.country} `;

  let latLngToUse = {
    lat: addressData?.latitude || null,
    lng: addressData?.longitude || null,
  };

  if (latLngToUse.lat === null || latLngToUse.lng === null) {
    await getGeocode({
      address: `${address} ${postalCode} ${city} ${country}`,
    })
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        updateWorksiteAddressFromKeyValue({
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
        latLngToUse = latLng;
      })
      .catch(() => false);
  }

  return {
    address,
    postalCode,
    city,
    country,
    latLngToUse,
  };
};

export const stringToBoolean = (str: 'true' | 'false'): boolean => {
  return str === 'true';
};

export const createFileWithLinkedFile = async (
  fileUrl: string,
  fileName: string
): Promise<File> => {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export const checkCaching = () => {
  const version = localStorage.getItem('version');
  if (version !== packageJson.version) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload();
    }
    localStorage.removeItem('version');
    localStorage.setItem('version', packageJson.version);
  }
};

// Vérifie si une date est au format DD/MM/YYYY
export const isDateValidDDMMYYYY = (date: string) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  return dateRegex.test(date);
};

// Vérifie si une date est au format YYYY-MM-DD
export const isDateValidYYYYMMDD = (date: string) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(date);
};
